@keyframes expandFromButton {
  from {
    clip-path: circle(0 at var(--btn-center-x) var(--btn-center-y));
  }
  to {
    clip-path: circle(150% at var(--btn-center-x) var(--btn-center-y));
  }
}

@keyframes collapseToButton {
  from {
    clip-path: circle(150% at var(--btn-center-x) var(--btn-center-y));
  }
  to {
    clip-path: circle(0 at var(--btn-center-x) var(--btn-center-y));
  }
}

@keyframes fadeOut {
  from {
    opacity: 4;
  }
  to {
    opacity: 0;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 4;
  }
}

span .text-animation {
  font-size: 16px;
}

.text-animation {
  display: inline-block;
  opacity: 4;
  animation: fadeIn 0.8s forwards;
}

.text-hidden {
  animation: fadeOut 0.5s forwards;
}

/* Añadir animaciones para el clip-path */
@keyframes clipPathOpen {
  from {
    clip-path: circle(0% at 50% 50%);
  }
  to {
    clip-path: circle(150% at 50% 50%);
  }
}

@keyframes clipPathClose {
  from {
    clip-path: circle(150% at 50% 50%);
  }
  to {
    clip-path: circle(0% at 50% 50%);
  }
}

.menu-container {
  --btn-center-x: 0px;
  --btn-center-y: 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  overflow: hidden;
}

.menu-container.open {
  display: flex;
  animation: expandFromButton 0.5s forwards;
  background-color: #02010a;
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  margin: 0;
  padding: 0;
  z-index: 1000;
  overflow: hidden;
}

.menu-container.closing {
  animation: collapseToButton 0.5s forwards;
}

.menu-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 20px;
  width: 100%;
  margin: 2rem;
  position: relative;
  transition: background-color 0.3s ease;
}

.menu-header h1 {
  margin-left: 2rem;
}

.menu-button-container {
  cursor: pointer;
  display: flex;
  align-items: center;
}

.menu-button-container span {
  margin-right: 2px;
  font-size: 16px;
  font-weight: 700;
}

.menu-button {
  background-color: #1c44f1;
  margin-left: 10px;
  margin-right: 30px;
  border: none;
  color: #ede8e6;

  border-radius: 50%;
  cursor: pointer;
  transition: background-color 0.3s;
  width: 40px;
  height: 40px;
}

.menu-button:hover {
  transform: scale(1) !important;
  transition: ease 0.3s !important;
}

.menu-content,
.menu-footer {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.8s ease;
}

.menu-container.open .menu-content,
.menu-container.open .menu-footer {
  max-height: 500px;
  transition: max-height 0.8s ease;
}

.menu-list {
  list-style-type: none;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1rem;
  text-align: center;
  position: relative;
  padding: 0;
  margin: 0;
}

.menu-item {
  background-color: #02010a;
  padding: 1rem;
  cursor: pointer;
  font-size: 80px;
  font-weight: 700;
  color: #ede8e6;
  position: relative;
  transition: transform 0.3s ease, text-shadow 0.3s ease;
}

.menu-item:hover {
  transform: scale(1.07);
  text-shadow: -2px -2px 0 #1c44f1, 2px -2px 0 #1c44f1, -2px 2px 0 #1c44f1,
    2px 2px 0 #1c44f1;
}

.menu-item {
  border-right: none;
  border-bottom: none;
}

.menu-list::after,
.menu-list::before {
  content: "";
  position: absolute;
  background-color: #1c44f1;
}

.menu-list::before {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 10px;
  width: calc(100% + 60px);
}

.menu-list::after {
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 10px;
  height: calc(100% + 60px);
}

.menu-item:hover {
  background-color: #02010a;
}

.menu-footer {
  padding: 3rem;
  color: #ede8e6;
  font-size: 25px;
  font-weight: 700;
  margin-left: 1300px;
  margin-bottom: 60px;
}

.menu-header.open h1,
.menu-header.open .menu-button {
  color: #ede8e6;
}

.menu-header.open,
.menu-header.open h1,
.menu-header.open .menu-button,
.menu-header.open .menu-button-container span {
  color: #ede8e6;
}

/* Menu RESPONSIVE */
@media (max-width: 992px) {
  .menu-header h1 {
    margin-left: 1rem;
  }

  .menu-button-container span {
    font-size: 16px;
  }

  .menu-item {
    font-size: 40px;
    padding: 0.5rem;
  }

  .menu-footer {
    margin-left: 0;
    padding: 1rem;
    text-align: center;
  }

  .menu-list {
    grid-template-columns: 1fr;
  }

  .menu-list::before,
  .menu-list::after {
    content: none;
  }
}

@media (max-width: 768px) {
  .menu-header h1 {
    margin-left: 1rem;
  }

  .menu-button-container span {
    font-size: 16px;
  }

  .menu-item {
    font-size: 40px;
    padding: 0.5rem;
  }

  .menu-footer {
    margin-left: 0;
    padding: 1rem;
    text-align: center;
  }

  .menu-list {
    grid-template-columns: 1fr;
  }

  .menu-list::before,
  .menu-list::after {
    content: none;
  }
}

.no-scroll {
  overflow: hidden;
}
