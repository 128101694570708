


.slick-slider {
  width: 100%;
  margin: 0 auto; /* Centra el carrusel en su contenedor */
  margin-bottom: 20px;
}

/* Opcional: oscurecer las diapositivas no centrales para enfocar más en la central */
.slick-slide:not(.slick-center) {
  opacity: 0.8; /* Haz las diapositivas no centrales más transparentes */
}

.slick-list {

  margin: 0 auto; /* Centra el carrusel en su contenedor */
}

.slide-container {
  width: 100%;
  display: flex;
  justify-content: center;
}

@media (max-width: 768px) {
  .contenedor-del-carrusel {
    overflow: visible;
  }

  .slick-center {
    transform: scale(1.03); /* Menor escalamiento para pantallas más pequeñas */
  }
}



