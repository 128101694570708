/* SolutionSlider.css */
.slider-container {
  margin: 0 auto;
  overflow: hidden;
  padding-top: 50px; /* Add padding to the top */
  padding-bottom: 50px; /* Add padding to the bottom */

}

.slide {
  display: flex;
  justify-content: center;
  padding-right: 50px; /* Add padding to each slide */
  padding-left: 50px; /* Add padding to each slide */
}

